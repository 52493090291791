import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, DatePicker, Modal, Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import moment from 'moment';
import withStyles from 'react-jss';
import GetField from '../Components/GetField';
import { clearConditionalTasks, clearTaskMIS, getConditionalTasks, getTaskMIS } from '../Actions';
import { onFilter, sorter, filterDropdown } from '../utils';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import styles from '../CommonStyles';

const AntDatePicker = GetField(DatePicker);

const TaskMIS = props => {
  const { classes } = props;

  const dispatch = useDispatch();

  const taskMIS = useSelector(state => state.taskMIS.data);
  const weeks = useSelector(state => state.taskMIS.weeks);
  const conditionalTasks = useSelector(state => state.conditionalTasks);

  const FormValues = useSelector(state => getFormValues('TaskMIS')(state));

  const [ModalView, setModalView] = useState(false);
  const [UserId, setUserId] = useState(null);
  const [Condition, setCondition] = useState(null);
  const [Type, setType] = useState(null);
  const [Week, setWeek] = useState(null);

  useEffect(() => {
    return () => {
      dispatch(clearTaskMIS());
    };
  }, []);

  useEffect(() => {
    if (FormValues && FormValues.month) {
      dispatch(getTaskMIS({ month: moment(FormValues.month).toISOString() }));
    }
  }, [FormValues && FormValues.month]);

  const showModal = (id, type, condition, week) => {
    setModalView(true);
    setUserId(id);
    setType(type);
    setCondition(condition);
    setWeek(week);
  };

  const hideModal = () => {
    setModalView(false);
    setUserId(null);
    setType(null);
    setCondition(null);
    setWeek(null);
  };

  useEffect(() => {
    if (ModalView && UserId && FormValues && FormValues.month && Condition && Type && Week) {
      dispatch(
        getConditionalTasks({
          UserId,
          type: Type,
          condition: Condition,
          week: Week,
          month: moment(FormValues.month).toISOString(),
        })
      );
    }

    return () => {
      dispatch(clearConditionalTasks());
    };
  }, [UserId, ModalView, Type, Condition, Week, FormValues && FormValues.month]);

  // const kraScore = (record, week) => {
  //   let score = 0;
  //   if (!_.isEmpty(record[week])) {
  //     score =
  //       Math.round(
  //         +(
  //           (+record[week].totalKRATasksNotCompleted + +record[week].totalKRATasksCompletedDelayed) /
  //           +record[week].totalKRATasks
  //         ) * 100
  //       ) || 0;
  //   } else {
  //     score = '';
  //   }

  //   if (score === 0 || score === '') {
  //     return <span style={{ color: 'green' }}>{score}</span>;
  //   }
  //   return <span style={{ color: 'red' }}>{(score *= -1)}</span>;
  // };

  // const taskScore = (record, week) => {
  //   let score = 0;

  //   if (!_.isEmpty(record[week])) {
  //     score =
  //       Math.round(
  //         +(
  //           (+record[week].totalTasksNotCompleted + +record[week].totalTasksCompletedDelayed) /
  //           +record[week].totalTasks
  //         ) * 100
  //       ) || 0;
  //   } else {
  //     score = '';
  //   }

  //   if (score === 0 || score === '') {
  //     return <span style={{ color: 'green' }}>{score}</span>;
  //   }
  //   return <span style={{ color: 'red' }}>{(score *= -1)}</span>;
  // };

  const _columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      onFilter: onFilter('name'),
      filterDropdown,
      fixed: 'left',
      width: '6%',
    },
    {
      title: `Week ${weeks[0].week || ''} (${weeks[0].start || ''} - ${weeks[0].end || ''})`,
      dataIndex: 'week1',
      children: [
        {
          title: 'KRA',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week1.totalKRATasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week1.totalKRATasksNotCompleted > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'kra', 'notdone', 'week1')}>
                        {record.week1.totalKRATasksNotCompleted}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {-Math.round((record.week1.totalKRATasksNotCompleted / record.week1.totalKRATasks) * 100)} %
                    </span>
                  </>
                ) : (
                  record.week1.totalKRATasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week1.totalKRATasksCompletedDelayed > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'kra', 'dalay', 'week1')}>
                        {record.week1.totalKRATasksCompletedDelayed}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {
                        -Math.round(
                          (record.week1.totalKRATasksCompletedDelayed /
                            (+record.week1.totalKRATasks - +record.week1.totalKRATasksNotCompleted)) *
                            100
                        )
                      }{' '}
                      %
                    </span>
                  </>
                ) : (
                  record.week1.totalKRATasksCompletedDelayed
                ),
            },
          ],
        },
        {
          title: 'Task',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week1.totalTasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week1.totalTasksNotCompleted > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'task', 'notdone', 'week1')}>
                        {record.week1.totalTasksNotCompleted}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {-Math.round((record.week1.totalTasksNotCompleted / record.week1.totalTasks) * 100)} %
                    </span>
                  </>
                ) : (
                  record.week1.totalTasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week1.totalTasksCompletedDelayed > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'task', 'dalay', 'week1')}>
                        {record.week1.totalTasksCompletedDelayed}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {
                        -Math.round(
                          (record.week1.totalTasksCompletedDelayed /
                            (+record.week1.totalTasks - +record.week1.totalTasksNotCompleted)) *
                            100
                        )
                      }{' '}
                      %
                    </span>
                  </>
                ) : (
                  record.week1.totalTasksCompletedDelayed
                ),
            },
          ],
        },
      ],
    },
    {
      title: `Week ${weeks[1].week || ''} (${weeks[1].start || ''} - ${weeks[1].end || ''})`,
      dataIndex: 'week2',
      children: [
        {
          title: 'KRA',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week2.totalKRATasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week2.totalKRATasksNotCompleted > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'kra', 'notdone', 'week2')}>
                        {record.week2.totalKRATasksNotCompleted}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {-Math.round((record.week2.totalKRATasksNotCompleted / record.week2.totalKRATasks) * 100)} %
                    </span>
                  </>
                ) : (
                  record.week2.totalKRATasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week2.totalKRATasksCompletedDelayed > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'kra', 'dalay', 'week2')}>
                        {record.week2.totalKRATasksCompletedDelayed}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {
                        -Math.round(
                          (record.week2.totalKRATasksCompletedDelayed /
                            (+record.week2.totalKRATasks - +record.week2.totalKRATasksNotCompleted)) *
                            100
                        )
                      }{' '}
                      %
                    </span>
                  </>
                ) : (
                  record.week2.totalKRATasksCompletedDelayed
                ),
            },
          ],
        },
        {
          title: 'Task',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week2.totalTasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week2.totalTasksNotCompleted > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'task', 'notdone', 'week2')}>
                        {record.week2.totalTasksNotCompleted}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {-Math.round((record.week2.totalTasksNotCompleted / record.week2.totalTasks) * 100)} %
                    </span>
                  </>
                ) : (
                  record.week2.totalTasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week2.totalTasksCompletedDelayed > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'task', 'dalay', 'week2')}>
                        {record.week2.totalTasksCompletedDelayed}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {
                        -Math.round(
                          (record.week2.totalTasksCompletedDelayed /
                            (+record.week2.totalTasks - +record.week2.totalTasksNotCompleted)) *
                            100
                        )
                      }{' '}
                      %
                    </span>
                  </>
                ) : (
                  record.week2.totalTasksCompletedDelayed
                ),
            },
          ],
        },
      ],
    },
    {
      title: `Week ${weeks[2].week || ''} (${weeks[2].start || ''} - ${weeks[2].end || ''})`,
      dataIndex: 'week3',
      children: [
        {
          title: 'KRA',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week3.totalKRATasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week3.totalKRATasksNotCompleted > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'kra', 'notdone', 'week3')}>
                        {record.week3.totalKRATasksNotCompleted}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {-Math.round((record.week3.totalKRATasksNotCompleted / record.week3.totalKRATasks) * 100)} %
                    </span>
                  </>
                ) : (
                  record.week3.totalKRATasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week3.totalKRATasksCompletedDelayed > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'kra', 'dalay', 'week3')}>
                        {record.week3.totalKRATasksCompletedDelayed}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {
                        -Math.round(
                          (record.week3.totalKRATasksCompletedDelayed /
                            (+record.week3.totalKRATasks - +record.week3.totalKRATasksNotCompleted)) *
                            100
                        )
                      }{' '}
                      %
                    </span>
                  </>
                ) : (
                  record.week3.totalKRATasksCompletedDelayed
                ),
            },
          ],
        },
        {
          title: 'Task',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week3.totalTasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week3.totalTasksNotCompleted > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'task', 'notdone', 'week3')}>
                        {record.week3.totalTasksNotCompleted}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {-Math.round((record.week3.totalTasksNotCompleted / record.week3.totalTasks) * 100)} %
                    </span>
                  </>
                ) : (
                  record.week3.totalTasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week3.totalTasksCompletedDelayed > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'task', 'dalay', 'week3')}>
                        {record.week3.totalTasksCompletedDelayed}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {
                        -Math.round(
                          (record.week3.totalTasksCompletedDelayed /
                            (+record.week3.totalTasks - +record.week3.totalTasksNotCompleted)) *
                            100
                        )
                      }{' '}
                      %
                    </span>
                  </>
                ) : (
                  record.week3.totalTasksCompletedDelayed
                ),
            },
          ],
        },
      ],
    },
    {
      title: `Week ${weeks[3].week || ''} (${weeks[3].start || ''} - ${weeks[3].end || ''})`,
      dataIndex: 'week4',
      children: [
        {
          title: 'KRA',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week4.totalKRATasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week4.totalKRATasksNotCompleted > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'kra', 'notdone', 'week4')}>
                        {record.week4.totalKRATasksNotCompleted}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {-Math.round((record.week4.totalKRATasksNotCompleted / record.week4.totalKRATasks) * 100)} %
                    </span>
                  </>
                ) : (
                  record.week4.totalKRATasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week4.totalKRATasksCompletedDelayed > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'kra', 'dalay', 'week4')}>
                        {record.week4.totalKRATasksCompletedDelayed}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {
                        -Math.round(
                          (record.week4.totalKRATasksCompletedDelayed /
                            (+record.week4.totalKRATasks - +record.week4.totalKRATasksNotCompleted)) *
                            100
                        )
                      }{' '}
                      %
                    </span>
                  </>
                ) : (
                  record.week4.totalKRATasksCompletedDelayed
                ),
            },
          ],
        },
        {
          title: 'Task',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week4.totalTasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week4.totalTasksNotCompleted > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'task', 'notdone', 'week4')}>
                        {record.week4.totalTasksNotCompleted}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {-Math.round((record.week4.totalTasksNotCompleted / record.week4.totalTasks) * 100)} %
                    </span>
                  </>
                ) : (
                  record.week4.totalTasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week4.totalTasksCompletedDelayed > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'task', 'dalay', 'week4')}>
                        {record.week4.totalTasksCompletedDelayed}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {
                        -Math.round(
                          (record.week4.totalTasksCompletedDelayed /
                            (+record.week4.totalTasks - +record.week4.totalTasksNotCompleted)) *
                            100
                        )
                      }{' '}
                      %
                    </span>
                  </>
                ) : (
                  record.week4.totalTasksCompletedDelayed
                ),
            },
          ],
        },
      ],
    },
    {
      title: `Week ${weeks[4].week || ''} (${weeks[4].start || ''} - ${weeks[4].end || ''})`,
      dataIndex: 'week5',
      children: [
        {
          title: 'KRA',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week5.totalKRATasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week5.totalKRATasksNotCompleted > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'kra', 'notdone', 'week5')}>
                        {record.week5.totalKRATasksNotCompleted}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {-Math.round((record.week5.totalKRATasksNotCompleted / record.week5.totalKRATasks) * 100)} %
                    </span>
                  </>
                ) : (
                  record.week5.totalKRATasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week5.totalKRATasksCompletedDelayed > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'kra', 'dalay', 'week5')}>
                        {record.week5.totalKRATasksCompletedDelayed}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {
                        -Math.round(
                          (record.week5.totalKRATasksCompletedDelayed /
                            (+record.week5.totalKRATasks - +record.week5.totalKRATasksNotCompleted)) *
                            100
                        )
                      }{' '}
                      %
                    </span>
                  </>
                ) : (
                  record.week5.totalKRATasksCompletedDelayed
                ),
            },
          ],
        },
        {
          title: 'Task',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week5.totalTasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week5.totalTasksNotCompleted > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'task', 'notdone', 'week5')}>
                        {record.week5.totalTasksNotCompleted}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {-Math.round((record.week5.totalTasksNotCompleted / record.week5.totalTasks) * 100)} %
                    </span>
                  </>
                ) : (
                  record.week5.totalTasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week5.totalTasksCompletedDelayed > 0 ? (
                  <>
                    <span style={{ color: 'red' }}>
                      <Button type="link" block onClick={() => showModal(record.userId, 'task', 'dalay', 'week5')}>
                        {record.week5.totalTasksCompletedDelayed}
                      </Button>
                    </span>{' '}
                    <br />
                    <span style={{ color: 'red' }}>
                      {
                        -Math.round(
                          (record.week5.totalTasksCompletedDelayed /
                            (+record.week5.totalTasks - +record.week5.totalTasksNotCompleted)) *
                            100
                        )
                      }{' '}
                      %
                    </span>
                  </>
                ) : (
                  record.week5.totalTasksCompletedDelayed
                ),
            },
          ],
        },
      ],
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Task MIS" />
        <Row>
          <Field allowClear required name="month" placeholder="Month" picker="month" component={AntDatePicker} />
        </Row>
        <Table
          columns={_columns}
          bordered
          rowKey={record => record.name}
          dataSource={taskMIS}
          size="small"
          pagination={false}
          scroll={{ x: 2400, y: window.innerHeight - 300 }}
        />

        <Modal
          onCancel={hideModal}
          open={ModalView}
          centered
          width="95%"
          maskClosable={false}
          closable
          cancelText="Back"
          okButtonProps={{ className: classes.hide }}
          title="Tasks Detail"
        >
          <Table
            columns={[
              {
                title: 'Task Name',
                dataIndex: 'name',
              },
              {
                title: 'Remark',
                dataIndex: 'remark',
              },
              {
                title: 'Start At',
                dataIndex: 'startAt',
                render: date => moment(date).format('DD MMM, YY hh:mm A'),
              },
              {
                title: 'End At',
                dataIndex: 'endAt',
                render: date => moment(date).format('DD MMM, YY hh:mm A'),
              },
              {
                title: 'Completed At',
                dataIndex: 'completedAt',
                render: (text, record) =>
                  record.completedAt && moment(record.completedAt).diff(record.endAt, 'minutes') > 0 ? (
                    <span style={{ color: 'red' }}>{moment(text).format('DD MMM, YY hh:mm A')}</span>
                  ) : (
                    moment(text).format('DD MMM, YY hh:mm A')
                  ),
              },
              {
                title: 'Complete Remark',
                dataIndex: 'completeRemark',
              },
              {
                title: 'Duration',
                render: record => `${record.duration} ${record.durationType}`,
              },
            ]}
            bordered
            rowKey={record => record.id}
            dataSource={conditionalTasks}
            size="small"
            pagination={false}
            scroll={{ x: 1024 }}
          />
        </Modal>
      </>
    </Structure>
  );
};

TaskMIS.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StyledTaskMIS = withStyles(styles)(TaskMIS);

const FormedTaskMIS = reduxForm({
  form: 'TaskMIS',
  enableReinitialize: true,
})(StyledTaskMIS);

export default FormedTaskMIS;

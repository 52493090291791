import React, { useEffect, useCallback } from 'react';
import { Button, Card, Col, DatePicker, Input, Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { FileExcelOutlined, FileTextOutlined } from '@ant-design/icons';
import GetField from '../Components/GetField';
import {
  getOrdersASPReport,
  clearOrdersASPReport,
  getSaleASPReport,
  clearSaleASPReport,
  getOrderSaleReport,
  clearOrderSaleReport,
  getCustomerASPReport,
  clearCustomerASPReport,
  getProductASPTotal,
  clearProductASPReport,
} from '../Actions';
import { onFilter, sorter, filterDropdown } from '../utils';
import Structure from '../Structure/Structure';
import { errorNotification, errorNotificationOnBlob, infoNotification, successNotification } from '../Snackbar';
import Api from '../Api';

const AntDatePicker = GetField(DatePicker);
const AntInput = GetField(Input);

const ASPReports = () => {
  const dispatch = useDispatch();

  const formulationsASP = useSelector(state => state.ordersASP.formulations);
  const customersASP = useSelector(state => state.ordersASP.customers);
  const saleASP = useSelector(state => state.saleASP);
  const productASP = useSelector(state => state.productASP);
  const saleOrderReport = useSelector(state => state.saleOrderReport);
  const customerASPReport = useSelector(state => state.customerASPReport);

  const FormValues = useSelector(state => getFormValues('ASPReports')(state));

  useEffect(() => {
    return () => {
      dispatch(clearOrdersASPReport());
      dispatch(clearSaleASPReport());
      dispatch(clearProductASPReport());
      dispatch(clearOrderSaleReport());
      dispatch(clearCustomerASPReport());
    };
  }, []);

  // useEffect(() => {
  //   if (FormValues && FormValues.from && FormValues.to) {
  //     dispatch(
  //       getOrdersASPReport({
  //         from: moment(FormValues.from).toISOString(),
  //         to: moment(FormValues.to).toISOString(),
  //       })
  //     );
  //     dispatch(
  //       getSaleASPReport({
  //         from: moment(FormValues.from).toISOString(),
  //         to: moment(FormValues.to).toISOString(),
  //       })
  //     );
  //     dispatch(
  //       getProductASPTotal({
  //         from: moment(FormValues.from).toISOString(),
  //         to: moment(FormValues.to).toISOString(),
  //       })
  //     );
  //     dispatch(
  //       getOrderSaleReport({
  //         from: moment(FormValues.from).toISOString(),
  //         to: moment(FormValues.to).toISOString(),
  //       })
  //     );
  //   }
  // }, [FormValues && FormValues.from, FormValues && FormValues.to]);

  // useEffect(() => {
  //   if (FormValues && FormValues.from && FormValues.to && FormValues.target) {
  //     dispatch(
  //       getCustomerASPReport({
  //         from: moment(FormValues.from).toISOString(),
  //         to: moment(FormValues.to).toISOString(),
  //         target: FormValues.target,
  //       })
  //     );
  //   }
  // }, [FormValues && FormValues.from, FormValues && FormValues.to, FormValues && FormValues.target]);

  const onFetchReport = useCallback(async () => {
    try {
      dispatch(clearOrdersASPReport());
      dispatch(clearSaleASPReport());
      dispatch(clearProductASPReport());
      dispatch(clearOrderSaleReport());
      dispatch(clearCustomerASPReport());
      if (FormValues && FormValues.from && FormValues.to) {
        dispatch(
          getOrdersASPReport({
            from: moment(FormValues.from).toISOString(),
            to: moment(FormValues.to).toISOString(),
          })
        );
        dispatch(
          getSaleASPReport({
            from: moment(FormValues.from).toISOString(),
            to: moment(FormValues.to).toISOString(),
          })
        );
        dispatch(
          getProductASPTotal({
            from: moment(FormValues.from).toISOString(),
            to: moment(FormValues.to).toISOString(),
          })
        );
        dispatch(
          getOrderSaleReport({
            from: moment(FormValues.from).toISOString(),
            to: moment(FormValues.to).toISOString(),
          })
        );
      }
      if (FormValues && FormValues.from && FormValues.to && FormValues.target) {
        dispatch(
          getCustomerASPReport({
            from: moment(FormValues.from).toISOString(),
            to: moment(FormValues.to).toISOString(),
            target: FormValues.target,
          })
        );
      }
    } catch (err) {
      errorNotification(err);
    }
  }, [FormValues && FormValues.from, FormValues && FormValues.to, FormValues && FormValues.target]);

  const onFileDownload = useCallback(async () => {
    try {
      if (moment(FormValues.from).toISOString() && moment(FormValues.to).toISOString() && FormValues.target) {
        infoNotification('Downloading...');
        const { data } = await Api.getFile('/order-sales/export/aspreport', {
          from: moment(FormValues.from).toISOString(),
          to: moment(FormValues.to).toISOString(),
          target: FormValues.target,
        });
        fileDownload(data, `asp-report.xlsx`);
        successNotification('File downloaded');
      } else {
        infoNotification('Select From, To, Target for file Export');
      }
    } catch (err) {
      errorNotificationOnBlob(err);
    }
  }, [FormValues && FormValues.from, FormValues && FormValues.to, FormValues && FormValues.target]);

  const formulationsColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      onFilter: onFilter('name'),
      filterDropdown,
    },
    {
      title: 'Customers',
      dataIndex: 'customers',
      sorter: sorter('customers'),
      onFilter: onFilter('customers'),
      filterDropdown,
      render: text => (text?.length ? text.join(' ') : text),
    },
  ];

  const customersColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      onFilter: onFilter('name'),
      filterDropdown,
    },
  ];

  const customerSaleColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      onFilter: onFilter('name'),
      filterDropdown,
    },
    {
      title: 'Categories',
      dataIndex: 'categories',
      sorter: sorter('categories'),
      onFilter: onFilter('categories'),
      filterDropdown,
    },
    {
      title: 'Sale Total',
      dataIndex: 'finalSales',
      sorter: sorter('finalSales'),
      onFilter: onFilter('finalSales'),
      filterDropdown,
    },
  ];

  const productASPColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      onFilter: onFilter('name'),
      filterDropdown,
    },
    {
      title: 'Customers',
      dataIndex: 'customers',
      sorter: sorter('customers'),
      onFilter: onFilter('customers'),
      filterDropdown,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      sorter: sorter('total'),
      onFilter: onFilter('total'),
      filterDropdown,
    },
  ];

  const nbdColumns = [
    {
      title: 'Nature',
      dataIndex: 'nature',
      sorter: sorter('nature'),
      onFilter: onFilter('nature'),
      filterDropdown,
    },
    {
      title: 'Total #',
      dataIndex: 'counts',
      sorter: sorter('counts'),
      onFilter: onFilter('counts'),
      filterDropdown,
    },
    {
      title: 'Sale Total',
      dataIndex: 'value',
      sorter: sorter('value'),
      onFilter: onFilter('value'),
      filterDropdown,
    },
  ];

  const arsColumns = [
    {
      title: 'Customer',
      dataIndex: 'name',
      sorter: sorter('name'),
      onFilter: onFilter('name'),
      filterDropdown,
    },
    {
      title: 'Subcategory',
      dataIndex: 'subcategory',
      sorter: sorter('subcategory'),
      onFilter: onFilter('subcategory'),
      filterDropdown,
    },
    {
      title: 'Payment Category',
      dataIndex: 'paymentCategory',
      sorter: sorter('paymentCategory'),
      onFilter: onFilter('paymentCategory'),
      filterDropdown,
    },
    {
      title: 'Sold UGN',
      dataIndex: 'soldFormulations',
      sorter: sorter('soldFormulations'),
      onFilter: onFilter('soldFormulations'),
      filterDropdown,
    },
    {
      title: 'Not Sold UGN',
      dataIndex: 'notSoldFormulations',
      sorter: sorter('notSoldFormulations'),
      onFilter: onFilter('notSoldFormulations'),
      filterDropdown,
      render: (text, record) => record?.notSoldFormulations?.length && record?.notSoldFormulations?.join(' | ')
    },
    {
      title: 'Total Sales',
      dataIndex: 'totalSales',
      sorter: sorter('totalSales'),
      onFilter: onFilter('totalSales'),
      filterDropdown,
      render: (_text, record) => Number(record.totalSales).toFixed(2),
    },
    {
      title: 'Benchmark',
      dataIndex: 'benchmark',
      sorter: sorter('benchmark'),
      onFilter: onFilter('benchmark'),
      filterDropdown,
      render: (_text, record) => Number(record.benchmark).toFixed(2),
    },
    {
      title: 'Deviation',
      dataIndex: 'deviation',
      sorter: sorter('deviation'),
      onFilter: onFilter('deviation'),
      filterDropdown,
      render: (_text, record) => Number(record.deviation).toFixed(2),
    },
  ];

  return (
    <Structure>
      <>
        <Row gutter={[24, 0]}>
          <Col>
            <Field allowClear required name="from" placeholder="From" component={AntDatePicker} />
          </Col>
          <Col>
            <Field allowClear required name="to" placeholder="To" component={AntDatePicker} />
          </Col>
          <Col>
            <Field allowClear required name="target" placeholder="Target" component={AntInput} />
          </Col>
          <Col>
            <Button icon={<FileTextOutlined />} onClick={onFetchReport}>
              Fetch Report
            </Button>
          </Col>
          <Col>
            <Button icon={<FileExcelOutlined />} onClick={onFileDownload}>
              Export Report
            </Button>
          </Col>
        </Row>
        {/* <br /> */}
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Table
              title={() => 'Product Not Sold During Period'}
              columns={formulationsColumns}
              bordered
              rowKey={record => record.name}
              dataSource={formulationsASP}
              size="small"
              pagination={{
                defaultPageSize: 20,
                position: ['bottomLeft'],
                showTotal: total => `Total ${total} Items`,
              }}
              scroll={{ x: 500, y: window.innerHeight - 300 }}
            />
          </Col>
          <Col span={12}>
            <Table
              title={() => 'Customer Not Sold During Period'}
              columns={customersColumns}
              bordered
              rowKey={record => record.name}
              dataSource={customersASP}
              size="small"
              pagination={{
                defaultPageSize: 20,
                position: ['bottomLeft'],
                showTotal: total => `Total ${total} Items`,
              }}
              scroll={{ x: 500, y: window.innerHeight - 300 }}
            />
          </Col>
          <Col span={12}>
            <Table
              title={() => 'Sale Total During Period'}
              columns={customerSaleColumns}
              bordered
              rowKey={record => record.name}
              dataSource={saleASP}
              size="small"
              pagination={{
                defaultPageSize: 20,
                position: ['bottomLeft'],
                showTotal: total => `Total ${total} Items`,
              }}
              scroll={{ x: 500, y: window.innerHeight - 300 }}
            />
          </Col>
          <Col span={12}>
            <Table
              title={() => 'Product Sold Total During Period'}
              columns={productASPColumns}
              bordered
              rowKey={record => record.name}
              dataSource={productASP}
              size="small"
              pagination={{
                defaultPageSize: 20,
                position: ['bottomLeft'],
                showTotal: total => `Total ${total} Items`,
              }}
              scroll={{ x: 500, y: window.innerHeight - 300 }}
            />
          </Col>
          <Col span={12}>
            <Table
              title={() => 'NBD'}
              columns={nbdColumns}
              bordered
              rowKey={record => record.nature}
              dataSource={saleOrderReport?.natures}
              size="small"
              pagination={{
                defaultPageSize: 20,
                position: ['bottomLeft'],
                showTotal: total => `Total ${total} Items`,
              }}
              scroll={{ x: 500, y: window.innerHeight - 300 }}
            />
          </Col>
          <Col span={12}>
            <Card size="small" title="NBD Conversion Ratio">
              <span>{saleOrderReport?.ratio}</span>
            </Card>
          </Col>
          <Col span={24}>
            <Table
              title={() => 'Average Rupee Sale'}
              columns={arsColumns}
              bordered
              rowKey={record => record.id}
              dataSource={customerASPReport?.customers}
              size="small"
              pagination={{
                defaultPageSize: 20,
                position: ['bottomLeft'],
                showTotal: total => `Total ${total} Items`,
              }}
              scroll={{ x: 1024, y: window.innerHeight - 300 }}
            />
          </Col>
        </Row>
      </>
    </Structure>
  );
};

const FormedASPReports = reduxForm({
  form: 'ASPReports',
  enableReinitialize: true,
})(ASPReports);

export default FormedASPReports;
